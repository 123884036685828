
body{

  width: 100%;
  height: 100%;
  background-image: url('../assets/images/backgroound.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}



.container-event {
  position: relative;
  overflow: hidden;
}

.container{
  width: 1600px;
  margin: 0 auto;
  float: none;
}

@media screen and (max-width: 1600px) {
  .container{
    width: 85%;
    margin: 0 auto;
    float: none;
  }
}

h1 {
  font-size: xxx-large;
  font-weight: 700;
}


.some-container {
  justify-content: flex-start;
} 

.venue-container-main{
  background-color:#145e9744;
    width: 1200px;
    margin: auto;
}
.container-head{
  background-color: black;
}
.event-main{
  width: 900px;
  margin: auto;
  margin-top: 30px;
}

.venue-text {
  font-size: 16px;
  color: #333; 
}
.event-image {
  /* width: 400px; */
  width: 336px;
  margin-left: 18px;
  height: 100%;
  margin-left: auto;
  border-radius: 35px;
  margin-top: 0px;
  object-fit: fill;
}
.highlighted-text {
  color: #ffac00;
  margin: 13px auto;
}

.event-img{
  width: 160px;
  height: 100px;
  
}

#agreeTerms {
  margin-right: 10px;
}

.venue-wrapper {
  background-color: #1a202c;
  font-family: 'Poppins', sans-serif;

}

.event-list-item {
  background-color: #1a202c;
  border: 1px solid #4a5568;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 21px;
  align-items: center;
  justify-content: start;
  margin-left: 0;
  padding: 2.5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, border-color 0.3s;
}

.event-list-item:hover {
  background-color: #2d3748;
  border-color: #2d3748;
}

.event-list-item img {
  flex: 1;
  height: 151px;
  width: 21%;
  object-fit: cover;
}

.event-list-item .text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.event-list-item .title {
  font-size: 23px;
  font-weight: 700;
  color: #ffffff;
}

.event-list-item .event-id {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

.event-list-item .album-id {
  margin-top: 3.5px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.table-service{
  width: 467px;
  background-color:#c1bbbb;
  margin-left: 238px;
  border-radius: 30px;
  box-shadow: 20px 0px 0px #000; 
}
.loaction-text{
  color: white;  
  font-size: 24px;
  font-weight: 700;
}
.map{
  width: 431px;
    outline-style: none;
    border-radius: 30px;
}
.text-location{
  margin-top: 40px;
    margin-bottom: 16px;

}
.increment-button {
  margin-left: 5px;
}
 
.increment-button,
.decrement-button {
  background-color: green;
  color: white;
}
.display-contents {
  display: contents;
}
.container-head{
  background-color: black;
}

.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
  height: 270px!important;
  width: 100%!important;
  border-radius: 5px!important;
  position: relative!important;
  outline-style: none;
}

#date, #tableselection {
  width: 100%;
  margin-right: 40px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.form-title.sm {
  font-size: x-large;
  margin-bottom: 8px;
  margin-top: 20px;
  color: white;
}
input, optgroup, select, textarea {

  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  width: 100%;
  font-weight: inherit;
  border: 2px solid white !important;
  line-height: inherit;
  color: currentColor;
  padding: 0;
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 5px;
  
  
}
.top_mrg-booking{
  padding: 5px !important;
}
#date {
  width: 100%;
  margin-right: 40px;
}

#tableselection {
  width: 100%;
  margin-left: 30px;
}
.container.mx-auto {
  margin-left: 40px;
  width: -webkit-fill-available;
}

button.w-full.bg-blue.hover\:bg-blue-dark.text-white.font-bold.py-2.px-4.rounded {
  background-color: blue;
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 50px;
}

#totalGuests {
  padding: 10px;
  width: 98%;
  border-radius: 10px;
  margin-left: 0px;
}
textarea#bookingNote {
  width: 98%;
  margin-top: 5px;
  margin-left: 0px;
  border-radius: 5px;
}

input#dob {
  width: 30%;
}

input#agreeTerms {
  width: unset;
}
.custom-timepicker .react-time-picker-dropdown-icon {
  fill: grey;
}

.custom-timepicker .react-time-picker-dropdown-clock {
  color: grey;
}

.custom-timepicker .react-time-picker-dropdown-clock-selected {
color: rgb(181, 20, 20); 
}

input.tp_inputBox{
  color: white !important; 
}

.custom-timepicker-wrapper {
  position: relative;
}


.custom-timepicker .react-time-picker-dropdown-icon {
  fill: grey; 
}

.custom-timepicker .react-time-picker-dropdown-clock {
  color: grey;
}

.custom-timepicker .react-time-picker-dropdown-clock-selected {
color: rgb(181, 20, 20);
}

input.tp_inputBox{
  color: white !important; 
}

.custom-timepicker-wrapper {
  position: relative;
}






  span.rc-time-picker.input {
    width: 100%;
    
}

.custom-timepicker .react-time-picker__inputGroup__hour {
  width: 80px !important; 
}


.custom-timepicker .react-time-picker__inputGroup__minute {
  width: 80px !important;
}
.custom-timepicker .rc-time-picker-panel-input-wrap {
  border: none;
}
.text-darkgray {
    color: white;
    background: transparent;
    border-color: darkgray;
}

.custom-timepicker .rc-time-picker-input {
  
  color: white;
  border: none;
  height:40px;
  /* border: 2px solid black; */
  background-color: transparent;
  border: 2px solid #a29d9d;
  margin-top: 0;
  border-radius: 0;
 
}

.custom-timepicker .rc-time-picker-clear {
  color: white;
}
input#dob {
  width: 48%;
  display: block;
  color: darkgray;
}
.custom-input {
  background-color: transparent;
  border: 2px solid #a29d9d;
}
.custom-guest {
  background-color: transparent;
  border: 2px solid #a29d9d;
  /* border-radius: 10px; */
  /* color: darkgray; */
}
button.w-full.bg-blue.hover\:bg-blue-dark.text-white.font-bold.py-2.px-4.rounded {
  /* background-color: cornflowerblue; */
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 50px;
  /* color: darkgrey; */
}

.evet_block .mt-2{
  color:#fff;
}
.evet_block {
  margin-bottom: 50px;
}
.info {
  text-align: center;
  color: #fff;
}
.img_event{
  width: 300px;
}
.event_data{
  display: contents;
}
.event-card{
  background-color: azure;
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.event-card img{
  margin-bottom: 20px;
  width: 320px;
  object-fit: cover;
  height: 300px;
}
.month_text {
  color: #e38519;
  width: 100%;
  float: left;
  text-align: center;
}
.highlight-input {
  border: 2px solid darkgrey;/* Green color for border */
  background-color: blue!important;
  /* Add any other styling for highlighting */
  
}


.font-semibold {
  font-weight: 600;
  text-align: center;
}



.event-card:hover {
  box-shadow: 5px 5px 0px #f6b301;
}
.event-card p {
  font-weight: 800;
}

.text_right{
  text-align: end;
  margin-left: 190px;
}
.event-details{
  display: inline-flex;}
  .main_ttile {
    margin-top: 30px;
}


.main_ttile h1 {
    text-align: center;
    color: #fff;
    
  }
.main_ttile h2 {
    color: #fff;
}
/* 
.leaflet-container{
  z-index: -100;
} */
.leaflet-container {
  z-index: 0; /* Set a positive z-index for the map */
}

/* For the datepicker container */
 /* Set a higher z-index for the datepicker */
/* .react-datepicker-wrapper {
  z-index: 1;
} */

.text-date{
  background: transparent;
    /* color: darkgray; */
    border-color: darkgrey;
    margin: 6px 25px;
}
.upcoming{
  text-align: center;
  color: white;

}
.view-all{
  width: 124px;
  /* background-color: #cf7526; */
  background-color: blue !important;
  border-radius: 20px;
}
.current-event{
  width: 178px;
  /* background-color: #cf7526; */
  background-color: blue !important;
  border-radius: 20px;
}
.top_mrg_no_of_seats{
  margin-top: 30px !important;
}

.top_mrg {
  margin-top: 10px;
}
select option {
 color: #000;
 background: transparent;
 
}
select option[value="1"] {
  color: #000;
}
.date-birth{
  width: 49% !important;
}
.react-datepicker__triangle {
  display: none !important; /* Hide the triangle element */
}


.top_frm_bar input, .top_frm_bar button {
  background: transparent;
  color: #fff;
  border-radius: 0;
  margin-top: 0;
}
.top_frm_bar::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
.top_frm_bar::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
.top_frm_bar:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
.top_frm_bar:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.event-main input {
  padding: 5px;
  width: 100%;
}


.react-datepicker-wrapper {
  width: 100%;
}
.text-option{
  color: darkgray;
}
.cursor-pointer{
  color: white;
  border: 2px solid #ccc; /* Grey border */
  padding: 6px;
}
.map-container {
  height: 100vh; /* Set height to fill viewport vertically */
  width: 100vw; /* Set width to fill viewport horizontally */
}
.event-main-upcoming{
  width: 850px !important;
  margin: auto;
    margin-top: 30px;
}
.booking-notes-padding{
  padding: 5px !important;
}
.card-image{
  border-bottom: 2px solid white;
}
.table-section{
  border: 1px solid white;
}
.upcoming-img{
  border: 6px solid white;
}
.custom-select option:checked {
  display: none;
}

.css-tj5bde-Svg{
margin-top: -13px;
}

.css-1x0asxb-placeholder {
  margin-top: -13px;
}
.css-6jgccv-singleValue{
  margin-top: -13px;
}
@media (max-width: 768px) {
  /* Add responsive styles here */
  .container {
    width: 100%; /* Adjust as needed */
  }
}
